import React from "react"
import ServiceBtn from "./serviceBtn"
import * as servicesSectionStyles from "./servicesSection.module.css"

import ScrollAnimation from "react-animate-on-scroll"
import "animate.css"

export default class ServicesSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = { windowWidth: null, displayLongerCopy: false }
  }

  componentDidMount() {
    let width = window.innerWidth
    this.setState({ windowWidth: width, displayLongerCopy: true })
  }

  render() {
    return (
      <div className={servicesSectionStyles.container}>
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
          {this.props.servicesPage ? (
            <h1>OUR SERVICES</h1>
          ) : (
            <h2>OUR SERVICES</h2>
          )}
          <p>
            We offer a wide variety of professional automotive, residential,
            commercial, marine and aviation services, including, but not limited
            to window tinting, paint correction, ceramic coating, vinyl
            wrapping, paint protection film (PPF), detailing and much more!
            <br />
            <br />
            Please feel free to{" "}
            <span style={{ color: "#FF6633" }}>
              browse our services below
            </span>{" "}
            and get in touch to see how we can help.
          </p>
        </ScrollAnimation>
        <div className={servicesSectionStyles.servicesGrid}>
          <ServiceBtn
            link="/services/automotive/"
            title="Automotive"
            fileName="automotive.jpg"
            id="servicesAutomotive"
            animateIn="animate__fadeInLeft"
            mainCategory={true}
          />
          <ServiceBtn
            link="/services/residential/"
            title="Residential"
            fileName="residential.jpg"
            id="servicesResidnetial"
            animateIn="animate__fadeInRight"
            mainCategory={true}
          />
          <ServiceBtn
            link="/services/commercial/"
            title="Commercial"
            fileName="commercial.jpg"
            animateIn="animate__fadeInLeft"
            mainCategory={true}
          />
          <ServiceBtn
            link="/services/marine/"
            title="Marine"
            fileName="marine.jpg"
            animateIn="animate__fadeInUp"
            mainCategory={true}
          />
          <ServiceBtn
            link={null}
            title="Aviation"
            subtitle="(Coming soon)"
            fileName="aviation.jpg"
            animateIn="animate__fadeInRight"
            mainCategory={true}
          />
        </div>
      </div>
    )
  }
}
