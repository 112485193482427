/* eslint-disable react/jsx-filename-extension */
import React from "react"
import Layout from "../templates/pageLayout"
import ServicesSection from "../components/servicesSection"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

export default function Services({ pageContext }) {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout title="Our Services">
      <div className="container">
        <div>
          <Breadcrumb crumbs={crumbs} crumbSeparator=" > " />
          <ServicesSection servicesPage={true} />
        </div>
      </div>
    </Layout>
  )
}
